import axios from 'axios';
import { refreshToken } from './authUtils';
import LoggingService from './services/LoggingService';

const axiosInstance = axios.create({
  // baseURL: 'http://127.0.0.1:5000',
  // withCredentials: true,

  // For production, you might want to use:
  // baseURL: 'http://demat-backend-v2-dev.eu-west-3.elasticbeanstalk.com' ,
  // withCredentials: true,

  baseURL: 'https://api.demat.app',
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => {
    LoggingService.info('Request successful', { 
      url: response.config.url, 
      method: response.config.method,
      status: response.status 
    });
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      LoggingService.warn('Token expired, attempting refresh', { 
        url: originalRequest.url,
        method: originalRequest.method
      });

      try {
        const newToken = await refreshToken();
        
        if (newToken) {
          LoggingService.info('Token refreshed successfully');
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        } else {
          throw new Error('Failed to obtain new token');
        }
      } catch (refreshError) {
        LoggingService.error('Token refresh failed', { 
          error: refreshError.message,
          stack: refreshError.stack
        });
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    LoggingService.error('Request failed', { 
      url: originalRequest.url, 
      method: originalRequest.method,
      status: error.response?.status,
      message: error.message,
      stack: error.stack
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;