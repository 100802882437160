import React, { useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Button, Divider, Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddIcon from '@mui/icons-material/Add';
import { getColor, getFont, getLabelColor } from '../utils/themeUtils';
import { fetchSidebarData } from '../utils';
import LoggingService from '../services/LoggingService';

const labels = [
  { name: 'SAV', color: getLabelColor('SAV') },
  { name: 'Facturation', color: getLabelColor('Facturation') },
  { name: 'Devis', color: getLabelColor('Devis') },
  { name: 'RDV', color: getLabelColor('RDV') },
  { name: 'Urgence', color: getLabelColor('Urgence') },
  { name: 'Fournisseur', color: getLabelColor('Fournisseur') },
  { name: 'Personnel', color: getLabelColor('Personnel') },
  { name: 'Spam', color: getLabelColor('Spam') }
];

const statuses = ['À lire', 'Archivé', 'À suivre'];

const Sidebar = ({ 
  activeLabel, 
  setActiveLabel, 
  activeStatus, 
  setActiveStatus, 
  itemCounts, 
  itemStatusCounts, 
  onUserClick 
}) => {
  useEffect(() => {
    const loadSidebarData = async () => {
      try {
        const data = await fetchSidebarData();
        // Assuming fetchSidebarData returns { itemCounts, itemStatusCounts }
        // You might need to update this based on your actual implementation
        LoggingService.info('Sidebar data loaded successfully', data);
      } catch (error) {
        LoggingService.error('Error loading sidebar data', { error: error.message });
      }
    };

    loadSidebarData();
  }, []);

  return (
    <Box sx={{ 
      width: 240, 
      flexShrink: 0, 
      backgroundColor: getColor('background.primary'),
      color: getColor('text.primary'),
      fontFamily: getFont('primary'),
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    }}>
      <Typography variant="h6" sx={{ p: 2, borderBottom: `1px solid ${getColor('border')}`, fontWeight: 'bold' }}>
        Ma bibliothèque
      </Typography>
      
      <List sx={{ flexGrow: 1, overflowY: 'auto', py: 0 }}>
        {labels.map((label) => (
          <ListItem 
            button 
            key={label.name} 
            onClick={() => setActiveLabel(activeLabel === label.name ? null : label.name)}
            selected={activeLabel === label.name}
            sx={{ 
              py: 1,
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: getColor('background.selected'),
              },
              '&:hover': {
                backgroundColor: getColor('background.hover'),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
              <FiberManualRecordIcon sx={{ color: label.color, fontSize: '10px' }} />
            </ListItemIcon>
            <ListItemText 
              primary={label.name} 
              secondary={itemCounts[label.name] || 0}
              primaryTypographyProps={{ 
                variant: 'body2',
                fontWeight: activeLabel === label.name ? 'bold' : 'normal',
                noWrap: true,
              }}
              secondaryTypographyProps={{ 
                variant: 'caption',
                color: 'text.secondary',
                component: 'span',
              }}
              sx={{ 
                m: 0, 
                '& .MuiListItemText-primary': { display: 'inline', mr: 1 },
                '& .MuiListItemText-secondary': { display: 'inline' },
              }}
            />
          </ListItem>
        ))}
      </List>

      <Box sx={{ p: 2 }}>
        <Tooltip title="Cette fonctionnalité sera disponible bientôt.">
          <Button
            startIcon={<AddIcon />}
            fullWidth
            variant="contained"
            size="small"
            sx={{
              backgroundColor: getColor('button.primary'),
              '&:hover': {
                backgroundColor: getColor('button.hover'),
              },
            }}
          >
            Nouvelle catégorie
          </Button>
        </Tooltip>
      </Box>
      
      <Divider sx={{ mt: 0 }} />
      
      <List sx={{ py: 0 }}>
        {statuses.map((status) => (
          <ListItem 
            button 
            key={status} 
            onClick={() => setActiveStatus(activeStatus === status ? null : status)}
            selected={activeStatus === status}
            sx={{ 
              py: 1,
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: getColor('background.selected'),
              },
              '&:hover': {
                backgroundColor: getColor('background.hover'),
              },
            }}
          >
            <ListItemText 
              primary={status}
              secondary={itemStatusCounts[status] || 0}
              primaryTypographyProps={{ 
                variant: 'body2',
                fontWeight: activeStatus === status ? 'bold' : 'normal',
              }}
              secondaryTypographyProps={{ 
                variant: 'caption',
                color: 'text.secondary',
                component: 'span',
              }}
              sx={{ 
                m: 0, 
                '& .MuiListItemText-primary': { display: 'inline', mr: 1 },
                '& .MuiListItemText-secondary': { display: 'inline' },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;