import React from 'react';
import { Box, Grid } from '@mui/material';
import MessageCard from './MessageCard';

const MessageBoard = ({ data, actions, selectedCall, setSelectedCall, updateMessageAttribute, deleteMessage, handleActionChange }) => {
  return (
    <Box sx={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
      <Grid container spacing={2} sx={{ px: 2, pb: 2 }}>
        {data.map((message) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={message.id}>
            <MessageCard 
              message={message} 
              actions={actions}
              updateMessageAttribute={updateMessageAttribute}  // Ensure this is passed correctly
              deleteMessage={deleteMessage}
              handleActionChange={handleActionChange}
              setSelectedMessage={setSelectedCall}
              isSelected={selectedCall && selectedCall.id === message.id}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MessageBoard;