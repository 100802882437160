import React from 'react';
import { Button } from '@mui/material';
import axiosInstance from '../axiosConfig'; // Use the pre-configured axios instance
import enrichedTranscripts from '../data/enrichedTranscripts.json';
import { fetchSidebarData } from '../utils';

const SimulateMessageButton = ({ fetchData, setSidebarData }) => {
  const handleSimulateMessage = async () => {
    // Select a random transcript from the enriched transcripts
    const randomTranscript = enrichedTranscripts.transcripts[Math.floor(Math.random() * enrichedTranscripts.transcripts.length)];

    // const labelKeys = Object.keys(label);
    // const randomKey2 = labelKeys[Math.floor(Math.random() * labelKeys.length)];
    // const randomLabel = label[randomKey2];

    // const statuses = ['À lire', 'Archivé', 'À suivre'];
    // const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];

    const newMessage = {
      caller_phone_number: randomTranscript.caller_number,
      time_of_call: randomTranscript.date_time,
      duration: randomTranscript.duration,
      transcription: "123456789-42-Fart-Test",
    };

    try {
      const response = await axiosInstance.post('/api/messages', newMessage);
      console.log('Message simulé réponse:', response.data);
      fetchData();
      fetchSidebarData(setSidebarData); // Recalculate the counts for the sidebar
      // console.log('New simulated message added:', newMessage);
    } catch (error) {
      console.error('Erreur lors de la simulation du message:', error);
      if (error.response && error.response.status === 401) {
        alert('Unauthorized: Please log in again.');
      }
    }
  };

  return (
    <Button 
      variant="contained" 
      onClick={handleSimulateMessage}
      sx={{
        backgroundColor: 'purple',
        color: 'white',
        '&:hover': {
          backgroundColor: 'darkpurple',
        },
      }}
    >
      Simuler un nouveau message
    </Button>
  );
};

export default SimulateMessageButton;