import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, ToggleButtonGroup, ToggleButton, Divider, Snackbar, Alert, Button } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Sidebar from '../components/Sidebar';
import MessageBoard from '../components/MessageBoard';
import MessageTable from '../components/MessageTable';
import SimulateMessageButton from '../components/SimulateMessageButton';
import UserSettingsModal from '../components/UserSettingsModal';
import SidebarMenu from '../components/SidebarMenu';
import { 
  fetchData, 
  updateMessage, 
  deleteMessage, 
  fetchSidebarData, 
  updateUserSettings, 
  deleteUserAccount,
  syncGmail,
  handleOAuthCallback
} from '../utils';
import LoggingService from '../services/LoggingService';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [view, setView] = useState('tile');
  const [activeLabel, setActiveLabel] = useState(null);
  const [activeStatus, setActiveStatus] = useState(null);
  const [sidebarData, setSidebarData] = useState({ itemCounts: {}, itemStatusCounts: {} });
  const [showSettings, setShowSettings] = useState(false);
  const [error, setError] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [authStatus, setAuthStatus] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [processedEmails, setProcessedEmails] = useState([]);



  const loadData = useCallback(async () => {
    try {
      await fetchData(setData);
      const sidebarDataResult = await fetchSidebarData();
      setSidebarData(sidebarDataResult);
      LoggingService.info('Dashboard data loaded successfully');
    } catch (error) {
      setError('Failed to load data. Please try again later.');
      LoggingService.error('Error loading dashboard data', { error: error.message });
      showSnackbar('Failed to load data. Please try again later.', 'error');
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // // New useEffect for handling OAuth callback
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('auth');

    if (status) {
      setAuthStatus(status);
      // Remove the query parameters from the URL
      navigate('/dashboard', { replace: true });
    }
  }, [location, navigate]);

  // TODO: We ignore for now the session state and skip the propre handleOAuthCallback - to revisit later
  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   if (queryParams.get('auth')) {
  //     handleOAuthCallback(queryParams)
  //       .then(() => {
  //         setAuthStatus('success');
  //       })
  //       .catch(() => {
  //         setAuthStatus('error');
  //       })
  //       .finally(() => {
  //         navigate('/dashboard', { replace: true });
  //       });
  //   }
  // }, [location, navigate]);

//   useEffect(() => {
//     if (authStatus === 'success') {
//       showSnackbar('Gmail authentication successful', 'success');
//       loadData();  // Optionally, trigger data refresh here
//     } else if (authStatus === 'cancelled') {
//       showSnackbar('Gmail authentication was cancelled', 'warning');
//     } else if (authStatus === 'error') {
//       showSnackbar('Gmail authentication failed', 'error');
//     }
// }, [authStatus, loadData]);
useEffect(() => {
  if (authStatus) {
    const messages = {
      success: 'Gmail authentication successful',
      cancelled: 'Gmail authentication was cancelled',
      error: 'Gmail authentication failed',
    };
    showSnackbar(messages[authStatus] || 'Unknown status', authStatus === 'success' ? 'success' : 'error');
    if (authStatus === 'success') {
      loadData();
    }
    setAuthStatus(null); // Reset authStatus to avoid showing stale messages
  }
}, [authStatus, loadData]);

const handleGmailSync = async () => {
  setIsSyncing(true);
  try {
    const response = await syncGmail();
    if (response.authorization_url) {
      window.location.href = response.authorization_url;
    } else {
      setSnackbar({
        open: true,
        message: response.message || 'Unknown error occurred',
        severity: response.status === 'success' ? 'success' : 'error',
      });
    }
  } catch (error) {
    setSnackbar({
      open: true,
      message: 'Failed to sync Gmail',
      severity: 'error',
    });
    LoggingService.error('Error syncing Gmail:', error);
  } finally {
    setIsSyncing(false);
  }
};

  const updateMessageAttribute = useCallback(async (messageId, attributeName, newValue) => {
    try {
      const updatedFields = { [attributeName]: newValue };
      await updateMessage(messageId, updatedFields);
      
      setData(prevData => prevData.map(msg => 
        (msg.id === messageId ? { ...msg, ...updatedFields } : msg)
      ));
      
      const sidebarDataResult = await fetchSidebarData();
      setSidebarData(sidebarDataResult);
      
      LoggingService.info('Message updated successfully', { messageId, attributeName });
      showSnackbar('Message updated successfully', 'success');
    } catch (error) {
      LoggingService.error('Error updating message', { messageId, attributeName, error: error.message });
      showSnackbar('Failed to update the message. Please try again.', 'error');
    }
  }, []);

  const handleDeleteMessage = useCallback(async (id) => {
    try {
      await deleteMessage(id);
      setData((prevData) => prevData.filter((message) => message.id !== id));
      
      const sidebarDataResult = await fetchSidebarData();
      setSidebarData(sidebarDataResult);
      
      LoggingService.info('Message deleted successfully', { messageId: id });
      showSnackbar('Message deleted successfully', 'success');
    } catch (error) {
      LoggingService.error('Error deleting message', { messageId: id, error: error.message });
      showSnackbar('Failed to delete the message. Please try again.', 'error');
    }
  }, []);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
      LoggingService.info('View changed', { newView });
    }
  };

  const handleSaveSettings = async (settings) => {
    try {
      await updateUserSettings(settings);
      LoggingService.info('User settings saved successfully');
      showSnackbar('Settings saved successfully', 'success');
    } catch (error) {
      LoggingService.error('Error saving user settings', { error: error.message });
      showSnackbar('Failed to save settings. Please try again.', 'error');
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUserAccount();
      LoggingService.info('User account deleted successfully');
      // Redirect to login page
      window.location.href = '/login';
    } catch (error) {
      LoggingService.error('Error deleting user account', { error: error.message });
      showSnackbar('Failed to delete user account. Please try again.', 'error');
    }
  };

  const filteredData = useCallback(() => 
    data.filter(message => 
      (!activeLabel || message.purpose === activeLabel) &&
      (!activeStatus || message.status === activeStatus)
    ), [data, activeLabel, activeStatus]
  );

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', backgroundColor: '#f5f5f5' }}>
      <SidebarMenu />
      <Divider orientation="vertical" flexItem />
      <Sidebar 
        activeLabel={activeLabel}
        setActiveLabel={setActiveLabel}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        itemCounts={sidebarData.itemCounts}
        itemStatusCounts={sidebarData.itemStatusCounts}
        onUserClick={() => setShowSettings(true)}
      />
      <Divider orientation="vertical" flexItem />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Box sx={{ 
          height: '70px', // Fixed height to match other components
          borderBottom: '1px solid #e0e0e0', 
          backgroundColor: '#f5f5f5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 16px', // Consistent padding
        }}>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">Messages</Typography>
            <Button 
              variant="contained" 
              onClick={handleGmailSync} 
              disabled={isSyncing}
            >
              {isSyncing ? 'Syncing...' : 'Sync Gmail'}
            </Button>
            <Box sx={{ ml: 2 }}>
              <SimulateMessageButton fetchData={loadData} />
            </Box>
          </Box>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view mode"
            size="small" // Make toggle buttons smaller to fit in the header
          >
            <ToggleButton value="tile" aria-label="tile view">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table view">
              <TableRowsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {error && <Typography color="error" sx={{ p: 2 }}>{error}</Typography>}
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
          {view === 'tile' ? (
            <MessageBoard
              data={filteredData()}
              selectedCall={selectedCall}
              setSelectedCall={setSelectedCall}
              updateMessageAttribute={updateMessageAttribute}
              deleteMessage={handleDeleteMessage}
            />
          ) : (
            <MessageTable
              data={filteredData()}
              selectedCall={selectedCall}
              setSelectedCall={setSelectedCall}
              updateMessageAttribute={updateMessageAttribute}
              deleteMessage={handleDeleteMessage}
            />
          )}
        </Box>
      </Box>
      <UserSettingsModal
        open={showSettings}
        onClose={() => setShowSettings(false)}
        saveSettings={handleSaveSettings}
        deleteUser={handleDeleteUser}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;