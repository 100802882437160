import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Box, Typography, Container, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from '../authUtils';
import axiosInstance from '../axiosConfig'; // Add this import
import LoggingService from '../services/LoggingService';


const Login = ({ setIsLoggedIn }) => {
  const [isLogin, setIsLogin] = useState(true); // Toggle entre connexion et inscription
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook pour la navigation
  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to run when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setFormData({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
      setLoading(false);
      setError(null);
    }
  }, [isLogin]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isMounted.current) setLoading(true);
    try {
      if (isLogin) {
        const response = await axiosInstance.post('/api/login', {
          email: formData.email,
          password: formData.password,
        });
        const token = response.data.access_token;
        setAuthToken(token);
        if (isMounted.current) setIsLoggedIn(true);
        LoggingService.info('User logged in successfully', { email: formData.email });
        navigate('/dashboard');
      } else {
        if (formData.password !== formData.confirmPassword) {
          if (isMounted.current) setError("Les mots de passe ne correspondent pas.");
          return;
        }
        await axiosInstance.post('/api/register', {
          username: formData.username,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
        });
        LoggingService.info('User registered successfully', { email: formData.email });
        alert('Inscription réussie. Veuillez vous connecter.');
        if (isMounted.current) setIsLogin(true);
      }
    } catch (error) {
      LoggingService.error('Authentication error', { 
        isLogin: isLogin,
        email: formData.email,
        error: error.response?.data?.error || error.message
      });
      if (isMounted.current) setError(error.response?.data?.error || 'Une erreur est survenue.');
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to bottom right, #f7f7f7, #e0e0e0)', // Gradient background
        backgroundSize: 'cover', // Make sure the background covers the entire area
        padding: '20px',
      }}
    >
      <Container 
        maxWidth="xs"
        sx={{
          backgroundColor: '#ffffff',
          padding: { xs: '20px', sm: '30px', md: '40px' }, // Responsive padding
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px', // Added margin for small screens
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center' }}>
          {isLogin ? 'Se connecter' : 'Créer un compte'}
        </Typography>
        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{ width: '100%', mt: 2 }}
        >
          {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
          {!isLogin && (
            <>
              <TextField
                label="Nom d'utilisateur"
                name="username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                sx={{ backgroundColor: '#f1f3f5', borderRadius: '8px' }}
              />
              <TextField
                label="Prénom"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                sx={{ backgroundColor: '#f1f3f5', borderRadius: '8px' }}
              />
              <TextField
                label="Nom de famille"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                sx={{ backgroundColor: '#f1f3f5', borderRadius: '8px' }}
              />
            </>
          )}
          <TextField
            label="Adresse e-mail"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            sx={{ backgroundColor: '#f1f3f5', borderRadius: '8px' }}
          />
          <TextField
            label="Mot de passe"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            sx={{ backgroundColor: '#f1f3f5', borderRadius: '8px' }}
          />
          {!isLogin && (
            <TextField
              label="Confirmer le mot de passe"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
              sx={{ backgroundColor: '#f1f3f5', borderRadius: '8px' }}
            />
          )}
          {isLogin && (
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Se souvenir de moi"
              sx={{ mt: 2 }}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              mb: 2,
              padding: '12px',
              backgroundColor: '#28a745',
              color: '#fff',
              borderRadius: '8px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#218838',
              },
            }}
            disabled={loading}
          >
            {loading ? (isLogin ? 'Connexion...' : 'Inscription...') : (isLogin ? 'Se connecter' : "S'inscrire")}
          </Button>
        </Box>
        <Box
          sx={{
            mt: 2,
            textAlign: 'center',
            padding: '15px',
            borderRadius: '8px',
          }}
        >
          <Typography variant="body2" sx={{ color: '#666' }}>
            {isLogin ? "Vous n'avez pas de compte ? - Contactez moi!" : "Vous avez déjà un compte ?"}
          </Typography>
          <Button
            onClick={() => setIsLogin(!isLogin)}
            variant="outlined"
            sx={{
              mt: 1,
              color: '#007bff',
              borderColor: '#007bff',
              borderRadius: '8px',
              textTransform: 'none',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            {isLogin ? "S'inscrire" : "Se connecter"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;