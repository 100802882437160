import React, { useState, useEffect, useCallback } from 'react';
import { 
  Card, CardContent, CardActions, Typography, 
  IconButton, Box, Collapse, TextField, Button, 
  List, ListItem, ListItemText 
} from '@mui/material';
import { getColor, getFont, getLabelColor } from '../utils/themeUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PhoneIcon from '@mui/icons-material/Phone';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import MessageAttributeSelector from './MessageAttributeSelector';
import { fetchComments, postComment } from '../utils';
import LoggingService from '../services/LoggingService';

const MessageCard = ({ message, updateMessageAttribute, deleteMessage, setSelectedMessage, isSelected }) => {
  const [expanded, setExpanded] = useState(false);
  const [isEditingCallerName, setIsEditingCallerName] = useState(false);
  const [callerName, setCallerName] = useState(message.caller_name);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  const loadComments = useCallback(async () => {
    try {
      const fetchedComments = await fetchComments(message.id);
      setComments(fetchedComments);
      LoggingService.info('Comments loaded successfully', { messageId: message.id });
    } catch (error) {
      LoggingService.error('Error loading comments', { messageId: message.id, error: error.message });
    }
  }, [message.id]);

  useEffect(() => {
    if (expanded) {
      loadComments();
    }
  }, [expanded, loadComments]);

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
    setSelectedMessage(message);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleAddComment = async () => {
    if (comment.trim()) {
      try {
        await postComment(message.id, comment);
        setComment('');
        loadComments(); // Refresh comments after adding
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };

  const handleCallerNameEdit = () => {
    if (isEditingCallerName) {
      updateMessageAttribute(message.id, 'caller_name', callerName);
      LoggingService.info('Caller name updated', { messageId: message.id, newName: callerName });
    }
    setIsEditingCallerName(!isEditingCallerName);
  };

  const getDistanceColor = (distance) => {
    const maxDistance = 200;
    const ratio = Math.min(distance / maxDistance, 1);
    const hue = (1 - ratio) * 200; // 200 for blue, transitions to 0 for red
    return `hsl(${hue}, 80%, 50%)`;
  };

  const cardStyle = {
    borderLeft: `5px solid ${getLabelColor(message.purpose)}`,
    backgroundColor: getColor('background.secondary'),
    color: getColor('text.primary'),
    fontFamily: getFont('primary'),
    opacity: message.status === 'Archivé' ? 0.5 : 1,
    mb: 2,
    transition: 'all 0.3s ease',
    position: 'relative',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    }
  };

  const distanceChipStyle = {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: getDistanceColor(message.distance),
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '16px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  };

  const expandedStyle = {
    maxHeight: '300px', // Set a fixed height for the expanded section
    overflowY: 'auto', // Make it scrollable if content overflows
  };

  return (
    <Card sx={cardStyle}>
      <CardContent sx={{ pb: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2, pr: 7 }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography variant="h6" component="span">
                {message.phone_number}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
              {isEditingCallerName ? (
                <TextField
                  value={callerName}
                  onChange={(e) => setCallerName(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1 }}
                />
              ) : (
                <Typography 
                  variant="h6" 
                  component="div" 
                  sx={{ 
                    maxWidth: '300px', // Set the maximum width here
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'nowrap' 
                  }}
                >
                  {message.caller_name}
                </Typography>
              )}
              <IconButton size="small" onClick={handleCallerNameEdit} >
                {isEditingCallerName ? <DoneIcon sx={{ fontSize: '1rem' }} /> : <EditIcon sx={{ fontSize: '1rem' }} />}
              </IconButton>
            </Box>
          </Box>
          {message.status === 'À traiter' && (
            <NotificationsActiveIcon color="error" />
          )}
        </Box>
        <Typography variant="body2" sx={{ mb: 1 }}>
          {new Date(message.time_of_call).toLocaleString('fr-FR')} - {message.duration} secondes
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <MessageAttributeSelector 
            messageId={message.id}
            currentValue={message.purpose}
            attributeType="purpose"
            updateMessageAttribute={updateMessageAttribute}
          />
          <MessageAttributeSelector 
            messageId={message.id}
            currentValue={message.status}
            attributeType="status"
            updateMessageAttribute={updateMessageAttribute}
          />
        </Box>
        <Box sx={{ height: '4.5em', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', mt: 1, mb: 1 }}>
          <Typography variant="body2">
            {message.summary}
          </Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{ pt: 0, justifyContent: 'space-between' }}>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        <IconButton onClick={(e) => { e.stopPropagation(); deleteMessage(message.id); }}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
      <Box sx={distanceChipStyle}>
        <DirectionsCarIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
        {message.distance} km
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={expandedStyle}>
          <Typography variant="body2" sx={{ mb: 1 }}>{message.address} - {message.distance} km</Typography>
          <Typography paragraph>Full Transcript:</Typography>
          <Typography paragraph>
            {message.transcription}
          </Typography>
          <List>
            {comments.map((comment) => (
              <ListItem key={comment.id} sx={{ alignItems: 'flex-start' }}>
                <ListItemText
                  primary={`${comment.author} - ${new Date(comment.timestamp).toLocaleString('fr-FR')}`}
                  secondary={comment.content}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            ))}
          </List>
          <TextField
            fullWidth
            label="Add a comment"
            multiline
            rows={2}
            value={comment}
            onChange={handleCommentChange}
            margin="normal"
          />
          <Button variant="contained" onClick={handleAddComment} sx={{ mt: 1 }}>
            Add Comment
          </Button>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default MessageCard;