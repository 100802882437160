import React, { useState } from 'react';
import { Menu, MenuItem, Chip } from '@mui/material';
import theme from '../theme.json'; // Import theme from the correct path

const MessageAttributeSelector = ({ messageId, currentValue, attributeType, updateMessageAttribute }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Determine the correct attribute list and colors based on attributeType
  const attributeOptions = attributeType === 'purpose' 
    ? Object.keys(theme.colors.labels) 
    : attributeType === 'status' 
      ? Object.keys(theme.colors.status)
      : [];

  const getColor = (value) => {
    return attributeType === 'purpose' 
      ? theme.colors.labels[value] 
      : theme.colors.status[value];
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAttributeChange = (newValue) => {
    updateMessageAttribute(messageId, attributeType, newValue);  // Only call the update function passed from Dashboard.js
    handleClose();
  };

  return (
    <div>
      <Chip 
        label={currentValue}
        onClick={handleClick}
        clickable
        sx={{
          backgroundColor: getColor(currentValue),
          color: '#ffffff', // White text color for readability
          height: '24px', // Match MUI Chip height
          fontSize: '0.8125rem', // Font size to match the MUI Chip
          borderRadius: '16px', // MUI Chip default border radius
          maxWidth: '100%', // Ensure it doesn't exceed its container
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          verticalAlign: 'middle',
          boxSizing: 'border-box',
          whiteSpace: 'nowrap',
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)', // Smooth transitions
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: '15ch', // Adjust the width of the menu to ensure it fits the longest Chip
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 4px', // Add padding to center align the Chips
          },
        }}
      >
        {attributeOptions.map((option) => (
          <MenuItem 
            key={option} 
            onClick={() => handleAttributeChange(option)}
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              padding: '4px 0', // Adjust padding to ensure consistency
              width: '100%', // Ensure full width
            }}
          >
            <Chip 
              label={option}
              sx={{ 
                backgroundColor: getColor(option), 
                color: '#ffffff',
                fontSize: '0.8125rem', // Font size to match the MUI Chip
                height: '24px', // Match MUI Chip height
                borderRadius: '16px', // MUI Chip default border radius
                width: '100%', // Ensure Chips are of uniform width
                justifyContent: 'center',
              }} 
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MessageAttributeSelector;