// src/services/LoggingService.js

class LoggingService {
    static LEVELS = {
      ERROR: 'error',
      WARN: 'warn',
      INFO: 'info',
      DEBUG: 'debug'
    };
  
    static log(level, message, data = {}) {
      if (!Object.values(this.LEVELS).includes(level)) {
        console.error(`Invalid log level: ${level}`);
        return;
      }
  
      const timestamp = new Date().toISOString();
      const logEntry = {
        timestamp,
        level,
        message,
        ...data
      };
  
      // In development, log to console
      if (process.env.NODE_ENV !== 'production') {
        console[level](JSON.stringify(logEntry, null, 2));
        return;
      }
  
      // In production, you might want to send logs to a server or third-party service
      //this.sendToServer(logEntry);
    }
  
    static sendToServer(logEntry) {
      // This is where you'd implement the logic to send logs to your server
      // or a third-party logging service
      
      // Example using fetch (you'd replace this with your actual API endpoint)
      fetch('/api/logs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(logEntry),
      }).catch(error => {
        // If logging fails, fall back to console
        console.error('Failed to send log to server:', error);
        console.error('Original log:', logEntry);
      });
  
      // Alternatively, you could integrate with a service like Sentry:
      // if (Sentry) {
      //   Sentry.captureMessage(logEntry.message, {
      //     level: logEntry.level,
      //     extra: logEntry
      //   });
      // }
    }
  
    // Convenience methods for different log levels
    static error(message, data) {
      this.log(this.LEVELS.ERROR, message, data);
    }
  
    static warn(message, data) {
      this.log(this.LEVELS.WARN, message, data);
    }
  
    static info(message, data) {
      this.log(this.LEVELS.INFO, message, data);
    }
  
    static debug(message, data) {
      this.log(this.LEVELS.DEBUG, message, data);
    }
  }
  
  export default LoggingService;