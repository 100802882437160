import React, { useState } from 'react';
import { Box, Divider, List, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import UserSettingsModal from './UserSettingsModal';
import { useNavigate } from 'react-router-dom';
import { logout } from '../authUtils';
import LoggingService from '../services/LoggingService';

const logo = `${process.env.PUBLIC_URL}/tmp-logo.svg`;

const SidebarMenu = ({ username }) => {
  const navigate = useNavigate();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleOpenSettings = () => {
    setSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };

  const handleSignOut = () => {
    logout();
    LoggingService.info('User signed out');
    navigate('/login');
  };

  const menuItems = [
    { text: 'Messages', icon: <DashboardIcon />, route: '/', enabled: true },
    { text: 'Contacts', icon: <PeopleIcon />, route: '/contacts', enabled: false },
    { text: 'Équipe', icon: <GroupIcon />, route: '/team', enabled: false },
    { text: 'Préfèrences', icon: <SettingsIcon />, route: '/settings', enabled: false },
  ];

  return (
    <Box
      sx={{
        height: '100vh',
        width: 200, // Fixed width
        backgroundColor: '#f4f4f4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
      }}
    >
      {/* App Logo and Name */}
      <Box
        sx={{
          height: '64px', // Fixed height to match AppBar height
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 0',
        }}
      >
        <img src={logo} alt="Logo" style={{ width: 30, height: 30, marginBottom: 2 }} />
        {/* <ListItemText
          // primary="demat"
          sx={{
            '& .MuiListItemText-primary': {
              fontWeight: 'bold',
              fontSize: '0.8rem',
              textAlign: 'center',
            },
          }}
        /> */}
      </Box>

      <Divider sx={{ width: '100%'}} />

      {/* Menu Items */}
      <List sx={{ width: '100%', flexGrow: 1, overflowY: 'auto', padding: 0 }}>
        {menuItems.map((item, index) => (
          <Tooltip title={item.enabled ? item.text : 'En développement'} placement="right" arrow key={index}>
            <ListItem
              button
              onClick={() => item.enabled && navigate(item.route)}
              disabled={!item.enabled}
              sx={{
                padding: '12px 0',
                justifyContent: 'center',
                color: item.enabled ? 'inherit' : 'gray',
              }}
            >
              <ListItemIcon sx={{ justifyContent: 'center', minWidth: 'auto' }}>
                {item.icon}
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        ))}
      </List>

      <Divider sx={{ width: '100%', mt: 'auto' }} />

      {/* Account Section */}
      <Tooltip title="Compte" placement="right" arrow>
        <ListItem
          button
          onClick={handleOpenSettings}
          sx={{
            padding: '12px 0',
            justifyContent: 'center',
          }}
        >
          <ListItemIcon sx={{ justifyContent: 'center', minWidth: 'auto' }}>
            <AccountCircleIcon />
          </ListItemIcon>
        </ListItem>
      </Tooltip>

      {/* Sign Out Option */}
      <Tooltip title="Déconnexion" placement="right" arrow>
        <ListItem
          button
          onClick={handleSignOut}
          sx={{
            padding: '12px 0',
            justifyContent: 'center',
          }}
        >
          <ListItemIcon sx={{ justifyContent: 'center', minWidth: 'auto' }}>
            <LogoutIcon />
          </ListItemIcon>
        </ListItem>
      </Tooltip>

      {/* User Settings Modal */}
      <UserSettingsModal open={settingsOpen} onClose={handleCloseSettings} username={username} />
    </Box>
  );
};

export default SidebarMenu;