import React, { useState, useEffect } from 'react';
import { Box, Modal, TextField, Button, Typography, CircularProgress, Divider, Autocomplete, Chip } from '@mui/material';
import { fetchUserSettings, updateUserSettings, deleteUserAccount } from '../utils';
import { useNavigate } from 'react-router-dom';
import servicesData from '../data/services.json';

const UserSettingsModal = ({ open, onClose, username }) => {
  const [settings, setSettings] = useState({
    market: '',
    services: [],
    company_name: '',
    area_of_intervention: '',
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleteInput, setDeleteInput] = useState('');
  const navigate = useNavigate();

  const availableSectors = Object.keys(servicesData.services);

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchUserSettings(setSettings).finally(() => setLoading(false));
    }
  }, [open]);

  const handleChange = (field) => (event) => {
    setSettings({ ...settings, [field]: event.target.value });
  };

  const handleServicesChange = (event, newValue) => {
    if (newValue.includes('Ajouter tout')) {
      const allServices = servicesData.services[settings.market] || [];
      setSettings({ ...settings, services: allServices });
    } else {
      setSettings({ ...settings, services: newValue });
    }
  };

  const handleSave = () => {
    setSaving(true);
    const updatedSettings = {
      ...settings,
      services: settings.services.join(',')
    };
    updateUserSettings(updatedSettings)
      .then(() => {
        onClose();
      })
      .finally(() => setSaving(false));
  };

  const handleDeleteAccount = () => {
    if (deleteInput === 'Supprimer') {
      deleteUserAccount(navigate).then(() => {
        onClose();
      });
    }
  };

  const availableServices = servicesData.services[settings.market] || [];

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="user-settings-modal">
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 500,  // Increase the width of the form for better layout
        maxHeight: '90vh',
        bgcolor: 'background.paper', 
        boxShadow: 24, 
        p: 4, 
        borderRadius: 2, 
        overflowY: 'auto',
      }}>
        <Typography variant="h6" gutterBottom>
          Paramètres de l'utilisateur - {username}
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Autocomplete
              options={availableSectors}
              value={settings.market}
              onChange={(event, newValue) => setSettings({ ...settings, market: newValue })}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Secteur" 
                  margin="normal"
                  helperText="Sélectionnez votre secteur d'activité"
                  fullWidth
                />
              )}
              fullWidth
              sx={{ mb: 3 }} // Add some space below the sector field
            />

            <Typography variant="subtitle1" gutterBottom>
              Services
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Sélectionnez les services que vous proposez. Vous pouvez en ajouter de nouveaux ou ajouter tous les services d'une catégorie.
            </Typography>
            <Autocomplete
              multiple
              freeSolo
              options={['Ajouter tout', ...availableServices]}
              value={settings.services}
              onChange={handleServicesChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Services"
                  placeholder="Ajoutez ou sélectionnez des services"
                  fullWidth
                />
              )}
              sx={{ 
                maxHeight: 200,  // Set a fixed height for the autocomplete input
                overflowY: 'auto',  // Make it scrollable when necessary
                mb: 3,
              }}
            />
            <TextField
              label="Nom de l'entreprise"
              fullWidth
              margin="normal"
              value={settings.company_name}
              onChange={handleChange('company_name')}
              sx={{ mb: 3 }}
            />
            <TextField
              label="Zone d'intervention"
              fullWidth
              margin="normal"
              value={settings.area_of_intervention}
              onChange={handleChange('area_of_intervention')}
              sx={{ mb: 3 }}
            />
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" onClick={handleSave} disabled={saving}>
                {saving ? <CircularProgress size={24} /> : 'Sauvegarder'}
              </Button>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Annuler
              </Button>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Typography variant="body2" color="error" gutterBottom>
              Supprimer le compte
            </Typography>
            <TextField
              label="Tapez 'Supprimer' pour confirmer"
              fullWidth
              margin="normal"
              value={deleteInput}
              onChange={(e) => setDeleteInput(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button 
              variant="contained" 
              color="error" 
              fullWidth 
              onClick={handleDeleteAccount} 
              disabled={deleteInput !== 'Supprimer'}
            >
              Supprimer définitivement
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UserSettingsModal;