import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TableSortLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getLabelColor } from '../utils/themeUtils';
import MessageAttributeSelector from './MessageAttributeSelector';


const MessageTable = ({ data, actions, selectedCall, setSelectedCall, updateMessageAttribute, deleteMessage, handleActionChange }) => {
  const [orderBy, setOrderBy] = useState('time_of_call');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  

  const sortedData = React.useMemo(() => {
    const comparator = (a, b) => {
      if (orderBy === 'time_of_call') {
        return order === 'asc'
          ? new Date(a.time_of_call) - new Date(b.time_of_call)
          : new Date(b.time_of_call) - new Date(a.time_of_call);
      }
      if (orderBy === 'distance') {
        return order === 'asc' ? a.distance - b.distance : b.distance - a.distance;
      }
      return 0;
    };
    return [...data].sort(comparator);
  }, [data, order, orderBy]);

  const handleRowClick = (call) => {
    setSelectedCall(call);
  };

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: '#f5f5f5', boxShadow: 'none' }}>
      <Table sx={{ minWidth: 650 }} aria-label="message table">
        <TableHead>
          <TableRow>
            <TableCell>Numéro de Téléphone</TableCell>
            <TableCell>Nom de l'appelant</TableCell>
            <TableCell sortDirection={orderBy === 'time_of_call' ? order : false}>
              <TableSortLabel
                active={orderBy === 'time_of_call'}
                direction={orderBy === 'time_of_call' ? order : 'asc'}
                onClick={() => handleRequestSort('time_of_call')}
              >
                Heure de l'appel
              </TableSortLabel>
            </TableCell>
            <TableCell>Durée</TableCell>
            <TableCell>Objectif</TableCell>
            <TableCell sortDirection={orderBy === 'distance' ? order : false}>
              <TableSortLabel
                active={orderBy === 'distance'}
                direction={orderBy === 'distance' ? order : 'asc'}
                onClick={() => handleRequestSort('distance')}
              >
                Distance
              </TableSortLabel>
            </TableCell>
            <TableCell>Résumé</TableCell>
            <TableCell>Supprimer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => (
            <TableRow
              key={row.id}
              sx={{ 
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#e0e0e0' },
                borderLeft: `4px solid ${getLabelColor(row.purpose)}`,
              }}
              onClick={() => handleRowClick(row)}
              selected={selectedCall && selectedCall.id === row.id}
            >
              <TableCell>{row.phone_number}</TableCell>
              <TableCell>{row.caller_name}</TableCell>
              <TableCell>{new Date(row.time_of_call).toLocaleString('fr-FR')}</TableCell>
              <TableCell>{row.duration} secondes</TableCell>
              <TableCell>
                {/* <Chip 
                  label={row.purpose} 
                  size="small" 
                  sx={{ 
                    backgroundColor: getLabelColor(row.purpose), 
                    color: 'white',
                    fontWeight: 'bold'
                  }} 
                /> */}
                {/* <MessagePurposeSelector
                  messageId={row.id}
                  currentPurpose={row.purpose}
                  updateMessagePurpose={updateMessagePurpose}  // Correctly pass the function from props
                /> */}
                <MessageAttributeSelector 
                  messageId={row.id}
                  currentValue={row.purpose}
                  attributeType="purpose"
                  updateMessageAttribute={updateMessageAttribute}
                />
              </TableCell>
              <TableCell>{row.distance} km</TableCell>
              <TableCell>{row.summary}</TableCell>
              <TableCell>
                <IconButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteMessage(row.id);
                  }} 
                  sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MessageTable;