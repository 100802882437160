import axiosInstance from './axiosConfig';
import LoggingService from './services/LoggingService';

// Helper function to handle API errors consistently
const handleApiError = (error, customMessage) => {
  const errorMessage = error.response?.data?.error || error.message || 'An unknown error occurred';
  LoggingService.error(customMessage, { error: errorMessage });
  throw new Error(errorMessage);
};

// Helper function to store OAuth state
const storeOAuthState = (state) => {
  localStorage.setItem('oauthState', state);
};

// Helper function to retrieve and clear OAuth state
const getAndClearOAuthState = () => {
  const state = localStorage.getItem('oauthState');
  localStorage.removeItem('oauthState');
  return state;
};

export const fetchData = async (callback) => {
  try {
    LoggingService.info('Fetching messages data');
    const response = await axiosInstance.get('/api/messages');
    if (typeof callback === 'function') {
      callback(response.data);
    }
    LoggingService.info('Messages data fetched successfully', { count: response.data.length });
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error fetching messages data');
  }
};

export const fetchActions = async () => {
  try {
    LoggingService.info('Fetching actions');
    const response = await axiosInstance.get('/api/settings');
    const actions = response.data.actions || ['Rappeler', 'Envoyer Devis', 'Plus Tard'];
    LoggingService.info('Actions fetched successfully', { actions });
    return actions;
  } catch (error) {
    handleApiError(error, 'Error fetching actions');
  }
};

export const updateMessage = async (messageId, updatedFields) => {
  try {
    LoggingService.info('Updating message', { messageId, updatedFields });
    const response = await axiosInstance.put(`/api/messages/${messageId}`, updatedFields);
    LoggingService.info('Message updated successfully', { messageId });
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating message');
  }
};

export const deleteMessage = async (id) => {
  try {
    LoggingService.info('Deleting message', { messageId: id });
    await axiosInstance.delete(`/api/messages/${id}`);
    LoggingService.info('Message deleted successfully', { messageId: id });
    return true;
  } catch (error) {
    handleApiError(error, 'Error deleting message');
  }
};

export const fetchSidebarData = async () => {
  try {
    LoggingService.info('Fetching sidebar data');
    const response = await axiosInstance.get('/api/messages');
    const messages = response.data;

    const itemCounts = messages.reduce((acc, message) => {
      acc[message.purpose] = (acc[message.purpose] || 0) + 1;
      return acc;
    }, {});

    const itemStatusCounts = messages.reduce((acc, message) => {
      acc[message.status] = (acc[message.status] || 0) + 1;
      return acc;
    }, {});

    LoggingService.info('Sidebar data fetched successfully', { itemCounts, itemStatusCounts });
    return { itemCounts, itemStatusCounts };
  } catch (error) {
    handleApiError(error, 'Error fetching sidebar data');
  }
};

export const fetchComments = async (messageId) => {
  try {
    LoggingService.info('Fetching comments', { messageId });
    const response = await axiosInstance.get(`/api/messages/${messageId}/comments`);
    LoggingService.info('Comments fetched successfully', { messageId, count: response.data.length });
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error fetching comments');
  }
};

export const postComment = async (messageId, content) => {
  try {
    LoggingService.info('Posting new comment', { messageId });
    const response = await axiosInstance.post(`/api/messages/${messageId}/comments`, { content });
    LoggingService.info('Comment posted successfully', { messageId, commentId: response.data.id });
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error posting comment');
  }
};

export const fetchUserSettings = async () => {
  try {
    LoggingService.info('Fetching user settings');
    const response = await axiosInstance.get('/api/user/settings');
    
    if (response.status === 200) {
      const settings = {
        market: response.data.market || '',
        services: response.data.services ? response.data.services.split(',') : [],
        company_name: response.data.company_name || '',
        area_of_intervention: response.data.area_of_intervention || ''
      };
      LoggingService.info('User settings fetched successfully');
      return settings;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      LoggingService.warn('User settings not found, initializing with default values');
      return {
        market: '',
        services: [],
        company_name: '',
        area_of_intervention: ''
      };
    } else {
      handleApiError(error, 'Error fetching user settings');
    }
  }
};

export const updateUserSettings = async (settings) => {
  try {
    LoggingService.info('Updating user settings');
    const response = await axiosInstance.put('/api/user/settings', settings);
    LoggingService.info('User settings updated successfully');
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating user settings');
  }
};

export const deleteUserAccount = async () => {
  try {
    LoggingService.info('Deleting user account');
    await axiosInstance.delete('/api/user');
    LoggingService.info('User account deleted successfully');
  } catch (error) {
    handleApiError(error, 'Error deleting user account');
  }
};

// export const syncGmail = async () => {
//   try {
//     LoggingService.info('Initiating Gmail sync');
//     const response = await axiosInstance.post('/api/sync-gmail');
    
//     if (response.data && response.data.authorization_url) {
//       LoggingService.info('Received authorization URL for Gmail sync');
//       if (response.data.state) {
//         storeOAuthState(response.data.state);
//         LoggingService.info('Stored OAuth state');
//       } else {
//         LoggingService.warn('No state received with authorization URL');
//       }
//       return response.data;
//     } else {
//       LoggingService.info('Gmail sync completed without requiring authorization');
//       return response.data;
//     }
//   } catch (error) {
//     handleApiError(error, 'Error syncing Gmail');
//     throw error; // Re-throw the error to allow the calling function to handle it
//   }
// };
export const syncGmail = async () => {
  try {
    LoggingService.info('Initiating Gmail sync');
    const response = await axiosInstance.post('/api/sync-gmail');
    
    if (response.data && response.data.authorization_url) {
      LoggingService.info('Received authorization URL for Gmail sync');
      const state = response.data.state;
      storeOAuthState(state);
      LoggingService.info(`Storing OAuth state: ${state}`);
      window.location.href = response.data.authorization_url;
    } else {
      LoggingService.info('Gmail sync completed without requiring authorization');
      return response.data;
    }
  } catch (error) {
    handleApiError(error, 'Error syncing Gmail');
    throw error; // Re-throw the error to allow the calling function to handle it
  }
};

export const initiateGmailAuth = async () => {
  try {
    LoggingService.info('Initiating Gmail authentication');
    const response = await axiosInstance.get('/api/initiate-gmail-auth');
    const state = response.data.state;
    storeOAuthState(state);
    window.location.href = response.data.authorization_url;
  } catch (error) {
    handleApiError(error, 'Error initiating Gmail authentication');
  }
};

export const handleOAuthCallback = async (urlParams) => {
  const state = urlParams.get('state');
  const storedState = getAndClearOAuthState();

  LoggingService.info(`Returned state: ${state}`);
  LoggingService.info(`Stored state: ${storedState}`);
  
  if (state !== storedState) {
    LoggingService.error('OAuth state mismatch');
    throw new Error('Invalid OAuth state');
  }

  const code = urlParams.get('code');
  if (!code) {
    LoggingService.error('No OAuth code received');
    throw new Error('No OAuth code received');
  }

  try {
    const response = await axiosInstance.post('/oauth2callback', { code, state });
    LoggingService.info('OAuth callback processed successfully');
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error processing OAuth callback');
  }
};