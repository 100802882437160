import axiosInstance from './axiosConfig';
import LoggingService from './services/LoggingService';

export const refreshToken = async () => {
  try {
    LoggingService.info('Attempting to refresh token');
    const response = await axiosInstance.post('/api/refresh-token');
    const newToken = response.data.access_token;
    if (!newToken) {
      throw new Error('New token not received from server');
    }
    localStorage.setItem('token', newToken);
    LoggingService.info('Token refreshed successfully');
    return newToken;
  } catch (error) {
    LoggingService.error('Error refreshing token', {
      error: error.message,
      stack: error.stack
    });
    throw error;
  }
};

export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
    LoggingService.info('Auth token set');
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
    LoggingService.info('Auth token removed');
  }
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const isAuthenticated = () => {
  const token = getAuthToken();
  return !!token; // Returns true if token exists, false otherwise
};

export const logout = () => {
  setAuthToken(null);
  LoggingService.info('User logged out');
  // You might want to redirect to login page or perform other logout actions here
};

export const decodeToken = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (error) {
    LoggingService.error('Error decoding token', {
      error: error.message,
      stack: error.stack
    });
    return null;
  }
};

export const getTokenExpirationDate = (token) => {
  const decodedToken = decodeToken(token);
  if (!decodedToken || !decodedToken.exp) return null;
  
  const expirationDate = new Date(0);
  expirationDate.setUTCSeconds(decodedToken.exp);
  return expirationDate;
};

export const isTokenExpired = (token) => {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate ? expirationDate.valueOf() < new Date().valueOf() : true;
};