// src/utils/themeUtils.js
import theme from '../theme.json';

export const getColor = (path) => {
  const keys = path.split('.');
  return keys.reduce((obj, key) => obj && obj[key], theme.colors) || '#000000'; // Fallback to black if not found
};

export const getStatusColor = (status) => {
  return theme.colors.status[status] || '#808080'; // Fallback to gray if status is not found
};

export const getFont = (key) => theme.fonts[key] || '';

export const getLabelColor = (label) => {
  // console.log('Label:', label);
  // console.log('Label Color:', theme.colors.labels[label]);
  return theme.colors.labels[label] || '#000000'; // Fallback to black
};